import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import ShareButtons from "../components/share-buttons"
import BorderBlog from "../icons/border-blog"
import CalendarBlog from "../icons/calendar-blog"
import Breadcrumb from '../components/breadcrumb'

class BlogPageTemplate extends React.Component {
    render() {
        const blogpage = this.props.data.contentfulBlog
        const image = getImage(blogpage.image)
        const allPosts = this.props.data.allContentfulBlog.edges
        // const url = "https://www.lndsolutions.org" + blogpage.slug
        return (
            <Layout>
                <Seo
                    keywords={[blogpage.category]}
                    title={blogpage.title}
                    description={blogpage.description}
                    location={blogpage.slug}
                    blogpage={blogpage}
                    isBlogPost={true}
                    type="BlogPosting"
                />
                <div>
                    <div className="container mx-auto px-5 lg:px-10 pt-10 lg:pt-16 pb-4 lg:pb-10">
                        <div className="max-w-4xl xl:mx-auto xl:max-w-full pb-4">
                            <nav className="flex container mx-auto sm:-ml-2 lg:-ml-6 pt-3 pb-6" aria-label="Breadcrumb">
                                <Breadcrumb links={[
                                    { to: '/', label: 'Home' },
                                    { to: '/blog', label: 'Blog' },
                                    { to: blogpage.slug, label: blogpage.title },
                                ]} />
                            </nav>
                            <p className="text-lndred text-sm lg:text-lg uppercase">{blogpage.category}</p>
                            <h1 className="py-2 font-bold text-2xl md:text-3xl lg:text-4xl">{blogpage.title}</h1>
                        </div>
                        <div className="flex justify-center">
                            <GatsbyImage className="w-full shadow-lg rounded-lg z-0 lg:h-[550px]" src="" image={image} title={blogpage.image.title} alt={blogpage.image.description} />
                        </div>
                        <div className="flex space-x-3 pt-5 px-1 font-semibold">
                            <span className="text-sm text-lndred">Guide</span>
                            <BorderBlog />
                            <div className="-mt-1">
                                <CalendarBlog />
                            </div>
                            <span className="text-sm text-gray-500">{blogpage.datePublished}</span>
                        </div>
                        {/* <div className="max-w-4xl mx-auto bg-white mt-4 px-5 rounded-lg shadow-lg">
                            <div className="flex mx-auto">
                                <span className="py-2 px-2 text-sm ">Share This Article:</span>
                                <div className="pt-2">
                                    <ShareButtons url={url} description={blogpage.description} title={blogpage.title} />
                                </div>
                            </div>
                        </div> */}
                        <div className="lg:hidden">

                            <div className="pt-10 max-w-4xl mx-auto" dangerouslySetInnerHTML={{
                                __html: this.props.data.contentfulBlog.body.childMarkdownRemark.html,
                            }}>
                            </div>

                            <div className='pt-10 text-left md:px-10'>
                                <div className="border rounded-xl p-5">
                                    <span className='font-bold text-xl'>Recent Posts</span>
                                    <div className='grid pt-5 space-y-5'>
                                        {allPosts && allPosts.slice(0, 3).map(({ node: post }) => {
                                            const image = getImage(post.image);
                                            return (
                                                <div key={post.slug}>
                                                    <div className="inline-flex">
                                                        <div className="max-w-xs">
                                                            <Link className="hover:opacity-80" to={post.slug}>
                                                                <GatsbyImage image={image} className="w-16 h-16 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" />
                                                            </Link>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className='px-2 text-xs text-lndred'>{post.category}</p>
                                                            <Link className="hover:opacity-80" to={post.slug}><p className='px-2 pt-1 text-xs font-bold'>{post.title}</p></Link>
                                                        </div>
                                                    </div>
                                                    <hr className="w-full" />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="hidden lg:flex space-x-10 xl:space-x-24 2xl:space-x-48">
                            <div className="pt-10 lg:max-w-xl xl:max-w-3xl 2xl:max-w-4xl" dangerouslySetInnerHTML={{
                                __html: this.props.data.contentfulBlog.body.childMarkdownRemark.html,
                            }}>
                            </div>

                            <div className='text-left lg:px-0'>
                                <div className="border rounded-xl p-5">
                                    <span className='font-bold text-xl'>Recent Posts</span>
                                    <div className='grid pt-5 space-y-5'>
                                        {allPosts && allPosts.slice(0, 3).map(({ node: post }) => {
                                            const image = getImage(post.image);
                                            return (
                                                <div key={post.slug}>
                                                    <div className="inline-flex">
                                                        <div className="max-w-xs">
                                                            <Link className="hover:opacity-80" to={post.slug}>
                                                                <GatsbyImage image={image} className="w-16 h-16 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" />
                                                            </Link>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className='px-2 text-xs text-lndred'>{post.category}</p>
                                                            <Link className="hover:opacity-80" to={post.slug}><p className='px-2 pt-1 text-xs font-bold'>{post.title}</p></Link>
                                                        </div>
                                                    </div>
                                                    <hr className="w-full" />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-7"></div>
                        <div className="text-center pt-5 pb-8 mx-auto md:flex md:justify-center lg:justify-start">
                            <Link to="/contact" title="Contact L&D Solutions" alt="Contact L&D Solutions">
                                <button className="w-1/2 md:w-[300px] lg:w-full h-9 px-4 text-sm lg:text-base lg:h-10 lg:px-6 bg-lndred rounded-lg border border-lndred focus:shadow-outline text-white hover:shadow-lg hover:shadow-lndred font-semibold transition duration-150 ease-in-out">
                                    <span>Contact Us</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}
export default BlogPageTemplate
export const pageQuery = graphql`
query ContentfulBlogBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulBlog( slug: {eq: $slug }) {
        title
        category
        slug
        author
        body {
            childMarkdownRemark {
                html
            }
         }
        description
        datePublished(formatString: "MMMM Do, YYYY")
        image {
             gatsbyImageData (
                width: 600
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
                quality: 100
                )
                title
                description
        }
    }
    allContentfulBlog(sort: { fields: datePublished, order: DESC }) {
        edges{
            node{
                title
                category
                slug
                author
                body {
                    childMarkdownRemark {
                        html
                    }
                }
                description
                datePublished(formatString: "MMMM Do, YYYY")
                image {
                   gatsbyImageData(
                       width: 600
                       placeholder: NONE
                       formats: [AUTO, WEBP, AVIF]
                   )
                   title
                   description
                }
            }
        }
    }
}
`