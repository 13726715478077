import * as React from "react"

function BorderBlog(props) {
    return (
        <svg className='' width="4" height="25" viewBox="0 0 1 40" fill="" xmlns="http://www.w3.org/2000/svg">
            <line opacity=".5" x1="0.5" y1="0.5" x2="0.499998" y2="39.5" stroke={props.color || 'black'} strokeLinecap="round" />
        </svg>

    )
}
export default BorderBlog
